import React from 'react';
import '../../styles/main.scss';

import TopBar from '../topbar/topbar';
import MobileNav from '../mobileNav/mobileNav';
import Helmet from "react-helmet"
import SEO from '../seo';
import { Link } from 'gatsby';

import featuredInImage from '../../assets/featured-companies.png';
import favicon from '../../assets/fav-icon.png'

const layout = (props) => {
    return (
        <>
            <Helmet
                title="Shark Dreams"
                link={[
                    { rel: 'shortcut icon', type: 'image/png', href: `${favicon}`}
                ]}
            />
            <SEO />
            <TopBar className="desktopNav" />
            <MobileNav className="mobileNav" />
            { props.children }
            <section className="featuredIn">
                <div className="featuredIn__imageContainer">
                    <img src={featuredInImage} className="featuredIn__image" />
                </div>
            </section>
            <footer className="footer">
                <div className="footer__content">
                    <div className="footer__navigation">
                        <ul className="footer__list">
                            <li className="footer__item">
                                <Link to="/contact" className="footer__link">Contact</Link>
                            </li>
                            <li className="footer__item">
                                <Link to="/privacy-policy" className="footer__link">Privacy Policy</Link>
                            </li>
                            <li className="footer__item">
                                <Link to="/terms-of-use" className="footer__link">Terms of Use</Link>
                            </li>
                            <li className="footer__item">Raleigh, NC | Hyderabad, India</li>
                            <li className="footer__item">&copy;2018 SharkDreams</li>
                        </ul>
                    </div>
                </div>
            </footer>
        </>
    );
};

export default layout;
