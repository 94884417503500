import React from 'react';
import { document, window } from 'browser-monads';

import { Link } from 'gatsby';
import Button from '../button/button';

const navBar = () => {

  const refresh = () => {
    window.location.href = '/';
  }

  return (
    <div className="navBar">
      <ul className="navBar__list">
        {/* For germany conference, home button needs to refresh the page */}
        {/* <li className="navBar__listItem"><Link className="navBar__listLink" activeClassName="navBar__listLinkActive" to="/">Home</Link></li> */}
        <li className="navBar__listItem"><Link onClick={refresh}className="navBar__listLink" activeClassName="navBar__listLinkActive" to="/">Home</Link></li>
        <li className="navBar__listItem"><Link className="navBar__listLink" activeClassName="navBar__listLinkActive" to="/about">About</Link></li>
        <li className="navBar__listItem"><Link className="navBar__listLink" activeClassName="navBar__listLinkActive" to="/products">Products</Link></li>
        <li className="navBar__listItem"><Link className="navBar__listLink" activeClassName="navBar__listLinkActive" to="/careers">Careers</Link></li>
        <li className="navBar__listItem"><Link className="navBar__listLink" activeClassName="navBar__listLinkActive" to="/blog">Blog</Link></li>
        <li className="navBar__listItem"><Link className="navBar__listLink" activeClassName="navBar__listLinkActive" to="/contact">Contact</Link></li>
        <Button outside className="button--primaryText button--bold" to="http://mylivit.com">Livit</Button>
      </ul>
    </div>
  );
};

export default navBar;
