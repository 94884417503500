module.exports = {
  siteTitle: "SharkDreams", // Site title.
  siteTitleShort: "SharkDreams", // Short site title for homescreen (PWA). Preferably should be under 12 characters to prevent truncation.
  siteTitleAlt: "Shark Dreams", // Alternative site title for SEO.
  siteLogo: "logo.jpg", // Logo used for SEO and manifest.
  // siteUrl: "https://sharkdreams.co", // Domain of your website without pathPrefix.
  siteUrl: "https://sharkdreams-prototype-responsive.surge.sh", // Domain of your website without pathPrefix.
  pathPrefix: "/", // Prefixes all links. For cases when deployed to example.github.io/gatsby-material-starter/.
  siteDescription: "Healthcare made simpler. SharkDreams is a cutting-edge digital healthcare company focused on human-centered, virtual care.", // Website description used for RSS feeds/meta description tag.
  siteRss: "/rss.xml", // Path to the RSS file.
  siteFBAppID: "1825356251115265", // FB Application ID for using app insights
  siteGATrackingID: "UA-89536651-1", // Tracking code ID for google analytics.
  // disqusShortname: "https-vagr9k-github-io-gatsby-material-starter", // Disqus shortname.
  postDefaultCategoryID: "tech", // Default category for posts.
  dateFromFormat: "YYYY-MM-DD", // Date format used in the frontmatter.
  dateFormat: "DD/MM/YYYY", // Date format for display.
  userName: "Dharma Nukarapu", // Username to display in the author segment.
  userTwitter: "", // Optionally renders "Follow Me" in the UserInfo segment.
  userLocation: "Raleigh, NC", // User location to display in the author segment.
  // userAvatar: "https://api.adorable.io/avatars/150/test.png", // User avatar to display in the author segment.
  userDescription:
    "A healthcare tech start-up founder", // User description to display in the author segment.
  // Links to social profiles/projects you want to display in the author segment/navigation bar.
  // userLinks: [
  //   {
  //     label: "GitHub",
  //     url: "https://github.com/Vagr9K/gatsby-material-starter",
  //     iconClassName: "fa fa-github"
  //   },
  //   {
  //     label: "Twitter",
  //     url: "https://twitter.com/Vagr9K",
  //     iconClassName: "fa fa-twitter"
  //   },
  //   {
  //     label: "Email"Material User,
  //     url: "mailto:vagr9k@gmail.com",
  //     iconClassName: "fa fa-envelope"
  //   }
  // ],
  copyright: "Copyright © 2018. SharkDreams" // Copyright string for the footer of the website and RSS feed.
};
