import React from 'react';

import { Link } from 'gatsby';

const button = (props) => {
  let classes = 'button';

  if (props.textOnly) {
    classes = 'buttonText';
  };

  if (props.className) {
    classes = classes + ' ' + props.className;
  }

  let buttonJsx = <Link className={classes} to={props.to}>{props.children}</Link>;

  if (props.outside) {
      buttonJsx = <a target="_blank" className={classes} href={props.to}>{props.children}</a>;
  } else if (props.button) {
      buttonJsx = <button className={classes} {...props}>{props.children}</button>;
  }

  return buttonJsx;
};

export default button;
