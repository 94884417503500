import React from 'react';

import { Link } from 'gatsby';

import NavBar from '../../components/navbar/navBar';

import logo from '../../assets/logo.png';

const topBar = () => {
  return (
      <div className="topBar">
          <div className="topBar__content">
              <div className="topBar__logo">
                  <Link to="/">
                      <img src={logo} alt="Logo" className="topBar__logoImage" />
                  </Link>
              </div>
              <div className="topBar__nav">
                  <NavBar />
              </div>
          </div>
    </div>
  );
};

export default topBar;
