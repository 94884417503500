import React from 'react';
import { Link } from 'gatsby';

const mobileNav = props => {

    const refresh = () => {
        window.location.href = '/';
    }

    let classes = 'navigation';

    if (props.className) {
        classes = 'navigation' + ' ' + props.className;
    }

    return (
        <div className={classes}>
            <input
                type="checkbox"
                className="navigation__checkbox"
                id="navigation-toggle"
            />
            <label className="navigation__button" htmlFor="navigation-toggle">
                <span className="navigation__icon" />
            </label>
            <div className="navigation__background">&nbsp;</div>
            <nav className="navigation__nav">
                <ul className="navigation__list">
                    <li className="navigation__item">
                        <Link to="/"
                            className="navigation__link"
                            onClick={ () => {
                                try {
                                    refresh();
                                    const checkbox = document.getElementById('navigation-toggle');
                                    const checkboxState = checkbox.checked
                                    checkbox.checked = !checkbox.checked
                                } catch(err) {}
                            } }>
                            <span>01</span>Home
                        </Link>
                    </li>
                    <li className="navigation__item">
                        <Link to="/about"
                            className="navigation__link"
                            onClick={ () => {
                                try {
                                    const checkbox = document.getElementById('navigation-toggle');
                                    const checkboxState = checkbox.checked
                                    checkbox.checked = !checkbox.checked
                                } catch(err) {}
                            } }>
                            <span>02</span>About
                        </Link>
                    </li>
                    <li className="navigation__item">
                        <Link to="/products"
                            className="navigation__link"
                            onClick={ () => {
                                try {
                                    const checkbox = document.getElementById('navigation-toggle');
                                    const checkboxState = checkbox.checked
                                    checkbox.checked = !checkbox.checked
                                } catch(err) {}
                            } }>
                            <span>03</span>Products
                        </Link>
                    </li>
                    <li className="navigation__item">
                        <Link to="/careers"
                            className="navigation__link"
                            onClick={ () => {
                                try {
                                    const checkbox = document.getElementById('navigation-toggle');
                                    const checkboxState = checkbox.checked
                                    checkbox.checked = !checkbox.checked
                                } catch(err) {}
                            } }>
                            <span>04</span>Careers
                        </Link>
                    </li>
                    <li className="navigation__item">
                        <Link to="/blog"
                            className="navigation__link"
                            onClick={ () => {
                                try {
                                    const checkbox = document.getElementById('navigation-toggle');
                                    const checkboxState = checkbox.checked
                                    checkbox.checked = !checkbox.checked
                                } catch(err) {}
                            } }>
                            <span>05</span>Blog
                        </Link>
                    </li>
                    <li className="navigation__item">
                        <Link to="/contact"
                            className="navigation__link"
                            onClick={ () => {
                                try {
                                    const checkbox = document.getElementById('navigation-toggle');
                                    const checkboxState = checkbox.checked
                                    checkbox.checked = !checkbox.checked
                                } catch(err) {}
                            } }>
                            <span>06</span>Contact
                        </Link>
                    </li>
                    <li className="navigation__item">
                        <a target="_blank" href="http://mylivit.com" className="navigation__link">
                            <span>07</span>Livit
                        </a>
                    </li>
                </ul>
            </nav>
        </div>
    );
};

export default mobileNav;
